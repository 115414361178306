<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'

/**
 * Contents component
 */
export default {
  page: {
    title: 'New Premium',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      title: 'New Premium',
      dataNewPremium: [],
      sub_title: 'sub_title',
      modalShow: false,
      dataSub: [],
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      await this.axios
        .get('/api/subscription/premium/getAllCategory')
        .then((res) => {
          this.dataNewPremium = res.data.result
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },
    async handleClickDeleteContent(id) {
      var confirmDel = confirm('Are you sure you want to delete?')

      if (confirmDel) {
        await this.axios
          .delete(`/api/banner/${id}`)
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },

    // async handleClickEditContent(data) {
    //   // console.log(data)
    //   this.$router.push({
    //     path: '/banner/create',
    //     query: { id: data._id },
    //   })
    // },
    async handleClickAddContent(data) {
      if (data.ancestors.length > 0) {
        this.$router.push({
          path: '/newPremium/showData',
          query: { id: data._id },
        })
      } else {
        this.$router.push({
          path: '/newPremium/content',
          query: { id: data._id },
        })
      }
    },
    async handleOk(bvModalEvt) {
      // await axios.patch(`/api/config?name=contentlabel`, {
      //   name: 'contentlabel',
      //   text: this.sub_title,
      // })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">New Premium</h4>
      </div>
      <div class="col-xl-6">
        <router-link to="/banner/create">
          <!-- <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          >
            <feather type="file-plus"></feather>Create
          </button> -->
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataNewPremium" :key="index">
                    <td scope="row">{{ index + 1 }}</td>

                    <td scope="row" style="width: 35%; height: 10%;">
                      {{ data.name }}
                    </td>

                    <td class="d-flex">
                      <button
                        v-if="data.ancestors.length"
                        class="btn btn-primary p-0 px-2 py-1 mr-2"
                        @click="handleClickAddContent(data)"
                        >Edit Sub</button
                      >
                      <button
                        v-else="!data.ancestors.length"
                        class="btn btn-info p-0 px-2 py-1 mr-2"
                        @click="handleClickAddContent(data)"
                        >Add Content</button
                      >
                      <!-- <button
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        @click="handleClickEditContent(data)"
                        >Edit</button
                      > -->

                      <!-- <b-button
                        @click="handleClickDeleteContent(banner._id)"
                        class="btn btn-danger p-0 px-2 py-1"
                        v-b-modal.modal-center
                        >Delete</b-button
                      > -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="lg" :title="sub_title" v-model="modalShow" @ok="handleOk">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in dataSub" :key="index">
              <td scope="row">{{ index + 1 }}</td>

              <td scope="row" style="width: 35%; height: 10%;">
                {{ data.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
.sizeImage {
  width: 50%;
  height: 50%;
}
</style>
